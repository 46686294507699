import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL, dbUrlWTDlist} from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Select from 'react-select';
import { SelectProperty, SearchLists, Mobile } from "../misc/utils";
import {DebounceInput} from 'react-debounce-input';

const Books = (props) => {
    const [books, setBooks] = useState([])
    // const [optionCategory, setOptionCategory] = useState('music')
    const [optionCategory, setOptionCategory] = useState('all')
    const [optionAuthor, setOptionAuthor] = useState(null)
    const [list, setList] = useState([])
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)

    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchBooks = () => {
          axios.get(dbURL + props.BookAPI + params.slug)
          .then(response => {
            setLoading2(false)
            setBooks(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        fetchList()
        fetchBooks()
      }, [params.slug, props.BookAPI])
  
    if (error) return `Error books: ${error.message}`;
    if (errorList) return `Error list: ${errorList.message}`;
    
    //console.table(books);
    //console.log(list);

    const isMobile = Mobile();

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>Oops! I can't find any books..</h1>
                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    let filterArray, authorOptions, tagArray;
    //categoryOptions

    //categoryOptions = SelectProperty(books, 'category')
    authorOptions = SelectProperty(books, 'author')

    /* const selectedCategory = (selectedCategory) => {
        setOptionCategory(selectedCategory);
    } */
    const selectedAuthor = (selectedAuthor) => {
        setOptionAuthor(selectedAuthor);
    }

    //console.log('Valgt category: ' + optionCategory);

    //tagfilter catgeory
    if (optionCategory === 'music') {
        tagArray = books.filter(e => e.category === 'Music');  
        }
    if (optionCategory === 'litteratur') {
        tagArray = books.filter(e => e.category === 'Litteratur');    
        } 
    if (optionCategory === 'travel') {
        tagArray = books.filter(e => e.category === 'Travel');  
        } 
    if (optionCategory === 'psychology') {
        tagArray = books.filter(e => e.category === 'Psychology');  
        } 
    if (optionCategory === 'philosophy') {
        tagArray = books.filter(e => e.category === 'Philosophy');  
        }
    if (optionCategory === 'art') {
        tagArray = books.filter(e => e.category === 'Art');  
        } 
    if (optionCategory === 'personal_development') {
        tagArray = books.filter(e => e.category === 'Personal development');  
        } 
    if (optionCategory === 'food_wine') {
        tagArray = books.filter(e => e.category === 'Food & Wine');  
        } 


        

        
        
    if (optionCategory === 'all') {
        tagArray = books;
        }

    //Search
    if (searchString) {
        filterArray = SearchLists(books, 'title', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

   //FILTERS
 /*    if (optionCategory) {
        filterArray = books.filter(e => e.category === optionCategory.value);
    }  */
    if (optionAuthor) {
        filterArray = books.filter(e => e.author === optionAuthor.value);
    } 

    if (tagArray && !searchString && !optionAuthor) {
        filterArray = tagArray;
    }

    if (!tagArray && !searchString && !optionCategory && !optionAuthor) {
        filterArray = books;
    }
   

    const {name, description, pic_link, page, use_tags} = list[0];

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>
            
                <div className="row mb-2 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-3 mt-1 Description">

                        <div><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        <div className="text-center">{value.pic_name}</div>


                        {value.pic2_link &&
                        <div className="mt-4 text-center">
                            <img src={value.pic2_link} alt="alt" className="img-fluid" /> 
                        </div>
                        }
                        {value.pic2_name &&
                            <div className="text-center">{value.pic2_name}</div>
                        }

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs mb-2">ALL count: {books.length}</div>
                                </div> 
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">FILTER count: {filterArray.length}</div>
                                </div>
                            </div>
                        }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-5 mt-1">

                        <h2>{value.name}</h2>

                        {value.description &&

                            <div className="factText16 rounded p-2">
                                <ReactMarkdown>
                                    {value.description}              
                                </ReactMarkdown>
                            </div>

                        }

                        {value.source_url &&
                            <div className="mt-3">
                                <b>List source</b>
                                <div className="Description">
                                    <ul>
                                        <li>
                                            <b><a href={value.source_url}>
                                                {value.name} ({value.source})
                                            </a></b>
                                        </li>  
                                    </ul>
                                </div>
                            </div>
                        }
                        
                        {value.comment1 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment1}
                                </blockquote>
                                {value.details1 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source1} <cite title="Source Title">({value.details1})</cite>
                                </figcaption>
                                }
                                {!value.details1 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source1}
                                </figcaption>
                                }
                            </figure>
                        }
                        {value.comment2 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment2}
                                </blockquote>
                                {value.details2 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source2} <cite title="Source Title">({value.details2})</cite>
                                </figcaption>
                                }
                                {!value.details2 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source2}
                                </figcaption>
                                }
                            </figure>
                        }
                        {value.comment3 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment3}
                                </blockquote>
                                {value.details3 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source3} <cite title="Source Title">({value.details3})</cite>
                                </figcaption>
                                }
                                {!value.details3 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source3}
                                </figcaption>
                                }
                            </figure>
                        }

                        {searchString &&
                            <div>
                                <div className='mt-3'>
                                    <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                </div> 
                            </div>
                        }
                        
                       
                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-4 mt-1">
                        <div className="row">   
                            <div className="col">
                                <h4>Filters</h4>
                            </div>
                        </div>
                        
                       {/*  {!value.no_category &&
                        <div className="row pb-2">  
                            <div className="col-9">
                                <Select
                                    className="select"
                                    value={optionCategory}
                                    onChange={selectedCategory}
                                    options={categoryOptions}
                                    placeholder='Select category'
                                    //styles={customStyles2}
                                />
                            </div>
                            <div className="col-3 pl-2">
                                <button  className="btn btn-primary btn-sm"  onClick={() => setOptionCategory(null)}>Reset</button>
                            </div>
                        </div>
                        } */}

                        <div className="row pb-2">  
                            <div className="col-9">
                                <Select
                                    className="select"
                                    value={optionAuthor}
                                    onChange={selectedAuthor}
                                    options={authorOptions}
                                    placeholder='Select author'
                                    //styles={customStyles2}
                                />
                            </div>
                            <div className="col-3 pl-2">
                                <button  className="btn btn-primary btn-sm"  onClick={() => setOptionAuthor(null)} >Reset</button>
                            </div>
                        </div>
                                        
                        <div className="row">   
                                <div className="col">
                                <h5>Search</h5>
                            </div>
                        </div>

                        <div className="row">  
                                <div className="col-9">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all titles" 
                                        />
                                    </div>
                                </div>
                                <div className="col-3 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>
                        </div>
                                
                        {use_tags &&
                        <div className="mt-4">
                            <div className="mx-4 bg-light py-1 ps-2"><b>Click to use filter:</b></div>
                            <div className="mb-1 mt-1 Text28">
                                {optionCategory === 'all' &&
                                <button type="button" className="btn btn-info me-2"
                                onClick={() => setOptionCategory('all')}><b>All  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'all' &&
                                <button type="button" className="btn btn-sm btn-info me-2"
                                onClick={() => setOptionCategory('all')}><b>All</b></button>
                                }
                                {optionCategory === 'music' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('music')}><b>Music  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'music' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('music')}><b>Music</b></button>
                                }
                                {optionCategory === 'litteratur' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('litteratur')}><b>Literature  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'litteratur' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('litteratur')}><b>Literature</b></button>
                                }
                                {optionCategory === 'travel' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('travel')}><b>Travel  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'travel' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('travel')}><b>Travel</b></button>
                                }
                                 {optionCategory === 'psychology' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('psychology')}><b>Psychology  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'psychology' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('psychology')}><b>Psychology</b></button>
                                }
                                {optionCategory === 'philosophy' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('philosophy')}><b>Philosophy  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'philosophy' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('philosophy')}><b>Philosophy</b></button>
                                }
                                 {optionCategory === 'art' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('art')}><b>Art  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'art' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('art')}><b>Art</b></button>
                                }
                                 {optionCategory === 'personal_development' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('personal_development')}><b>Personal development  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'personal_development' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('personal_development')}><b>Personal development</b></button>
                                }
                                 {optionCategory === 'food_wine' &&
                                <button type="button" className="btn btn-primary me-2"
                                onClick={() => setOptionCategory('food_wine')}><b>Food & Wine  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                                }
                                {optionCategory !== 'food_wine' &&
                                <button type="button" className="btn btn-sm btn-primary me-2"
                                onClick={() => setOptionCategory('food_wine')}><b>Food & Wine</b></button>
                                }

                                <hr className="bg-danger border-2 border-secondary mx-auto" style={{ width: '200px' }} />

                                <div className='mt-3'>
                                    <div className="btn btn-success btn-sm my-1 mx-2 p-2 Text14bold">Category - <strong>{optionCategory}</strong> - selected</div>
                                </div> 

                            </div>
                        </div>
                        }

                    </div>
                        
                </div>  
                   
            </React.Fragment>
            )
    })

    const bookItems = filterArray.map(value => {

        let DescCharLimit

        if (value.desc_char_limit) {
            DescCharLimit = value.desc_char_limit
        }
        else {
            DescCharLimit = 500
        }

        return (

            <React.Fragment key={value.id}>

                {value.super_slim &&
                    <div>
                        <div className="row BookSuperslim mx-1 my-4 p-2 border rounded border-4">
                            <div className='col'>
                                {value.picture &&
                                <div className="float-md-end ms-md-2">
                                    <img border="0" alt="album" src={value.picture} width="75" height="110" />
                                </div>  
                                }
                                {value.rank &&
                                    <div className="BookSlimTitle"><b>#{value.rank} - {value.title}</b></div>
                                }
                                {!value.rank &&
                                    <div className="BookSlimTitle"><b>{value.title}</b></div>
                                }
                                {value.author &&
                                    <div>{value.author} ({value.published_year}) - {value.original_language}</div> 
                                }
                            </div>
                        </div>
                    </div>
                }
                {!value.super_slim &&
                <div className="row mx-1 mt-4 border rounded border-4">

                    {/* 1 kolonne */}
                    <div className="col-sm-2 p-3">
                        <img src={value.picture} className="img-fluid rounded" alt="">
                        </img>
                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-7 pb-3 border-right">
                        
                            <div className="pt-1">
                                <div>
                                    {value.rank &&
                                    <h4><div className="badge bg-primary text-wrap">#{value.rank} - {value.title}</div>
                                    </h4>
                                    }
                                    {!value.rank &&
                                    <h4><div className="badge bg-primary text-wrap">{value.title}</div>
                                    </h4>
                                    }
                                </div>
                            </div>
                            <div align="pt-1">
                                <div>
                                    <h5><div className="badge bg-info text-wrap">{value.author}</div>
                                    </h5>
                                </div>
                            </div>
                           

                        
                            {value.description &&
                                <div className="factText rounded my-2 p-2"> 
                                            <ReadMoreAndLess
                                                charLimit={DescCharLimit}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess> 
                                            {value.more_info &&
                                            <div className="fst-italic text-end p-1">
                                            - <a href={value.more_info}>more info..</a>
                                            </div>
                                            }
                                </div>
                            }

                            {value.book_comment &&
                                <figure className="mt-2 p-2">
                                    <blockquote className="blockquote-small">
                                        {value.book_comment}
                                    </blockquote>
                                    {value.source_details &&
                                    <div className="blockquote-source-small ms-2">
                                        - {value.source} <cite title="Source Title">({value.source_details})</cite>
                                    </div>
                                    }
                                    {!value.source_details &&
                                    <figcaption className="blockquote-source-small ms-2">
                                        - {value.source}
                                    </figcaption>
                                    }
                                </figure>
                            }

                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-3 py-2">
                        
                            {value.publ_date &&
                                <div className="mt-2">
                                    <b>Published</b> <br />
                                    <div className="Date rounded py-1 ps-2">{value.publ_date}</div>
                                </div>
                            }
                            {value.published_year && !value.publ_date &&
                                <div className="mt-2">
                                    <b>Published</b> <br />
                                    <div className="DateTxt rounded py-1 ps-2">{value.published_year}</div>
                                </div>
                            }
                            {value.first_published &&
                                <div className="mt-2">
                                    <b>First published</b> <br />
                                    <div className="DateTxt rounded py-1 ps-2">{value.first_published}</div>
                                </div>
                            }
                            {value.original_language &&
                                <div className="mt-2">
                                    <b>Language</b> <br />
                                    <div className="DateTxt rounded py-1 ps-2">{value.original_language}</div>
                                </div>
                            }
                            {value.category &&
                                <div className="mt-2">
                                    <b>Category</b> <br />
                                    <div className="DateTxt rounded py-1 ps-2">{value.category}</div>
                                </div>
                            }
                             {value.subject &&
                                <div className="mt-2">
                                    <b>Subject</b> <br />
                                    <div className="DateTxt rounded py-1 ps-2">{value.subject}</div>
                                </div>
                            }
                             {value.translator &&
                                <div className="mt-2">
                                    <b>Translator</b> <br />
                                    <div className="DateTxt rounded py-1 ps-2">{value.translator}</div>
                                </div>
                            }

                        
                        
                        
                        
                        
                        
                        
                    </div>

                </div>
                }

            </React.Fragment>
        )

    })

    

    return (
        <main className="my-1 py-1">
            {books.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    {!isMobile &&
                    <div className="row">
                        <div className="col pb-1 me-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    }

                    <div className="row">
                        <div className="col">
                             {bookItems} 
                        </div>
                    </div>

                   

                    <div className="me-2" align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>


                </div>
            )}
        </main>
    )

}

export default Books