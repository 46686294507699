import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayPeopleWTD, dbURLAllAnyDayPeopleWTD, customStylesSmall } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import ReactMarkdown from 'react-markdown';
import { Mobile, SelectProperty, SearchLists, calculateAge } from "../misc/utils";
import { animateScroll as scroll } from "react-scroll";
import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';
//import { Link } from "react-router-dom";

const AnyDayPeople = (props) => {
    const [people, setPeople] = useState([])
    const [Allpeople, setAllPeople] = useState([])
    const [loadingPeople, setLoadingPeople] = useState(true)
    const [optionCat, SetOptionCat] = useState(null)
    //const [showAllPeople, setShowAllPeople] = useState(false);
    const [error, setError] = useState(null)
    const [errorAllPeople, setErrorAllPeople] = useState(null)
    const [searchString, setSearchString] = useState('')
    //let largeArray

    /* const ShowAllPeopleButton = () => {
    setShowAllPeople(true);
    }; */
   
    useEffect(() => {
      const fetchPeople = () => {
        axios.get(dbURLAnyDayPeopleWTD + props.dateSelected)
        .then(response => {
          setLoadingPeople(false)
          setPeople(response.data)
        }).catch(error => {
          setError(error);
        });
      }

      const fetchAllPeople = () => {
        axios.get(dbURLAllAnyDayPeopleWTD)
        .then(response => {
          //setLoadingAllPeople(false)
          setAllPeople(response.data)
        }).catch(errorAllPeople => {
            setErrorAllPeople(errorAllPeople);
        });
      }

      fetchPeople()
      fetchAllPeople()
    }, [props.dateSelected])

    if (error) return `Error: ${error.message}`;
    if (errorAllPeople) return `Error all people: ${errorAllPeople.message}`;

    const isMobile = Mobile();

    if (loadingPeople) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    let CatOptions, filterArray
    
    const scrollToBottom = () => {
        scroll.scrollToBottom();
        };

    //fjerne duplikater
    const peopleUnique  = people.filter(
        (obj, index) =>
        people.findIndex((item) => item.id === obj.id) === index
    );

    
    CatOptions = SelectProperty(Allpeople, 'cat')
    
    const SelectedOptionCat = (selectedCat) => {
        SetOptionCat(selectedCat);
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    if (searchString) {
        filterArray = SearchLists(Allpeople, 'searchname', searchString.toLowerCase());
        } 
    if (optionCat) {
        filterArray = Allpeople.filter(e => e.cat === optionCat.value);
        } 
    if (!optionCat && !searchString) {
        filterArray = peopleUnique;
        } 
    
    //console.log(Allpeople);

    const WisePeople = filterArray.map(value => {

        let DescCharLimit, born, age

        if (!value.dead) {
           age = calculateAge(value.birth_date)
        } 

        if (value.born) {
            born = value.born;
        }
        else {
            born = value.bd_text;
        }

        if (value.charlimit_description) {
            DescCharLimit = value.charlimit_description
        }
        else {
            DescCharLimit = 1000
        }

        return (

            <div key={value.id}>
                    <div className="row py-3 border-bottom border-top border-info">
                        
                        {/* kolonne 1 */}
                        <div className="col-sm-3">
                            
                                {isMobile &&
                                <div className="mb-2">
                                    <div className='ms-2 Text18 badge bg-primary text-wrap rounded'>
                                        {value.aka &&
                                            <div>{value.aka}</div>
                                        }
                                        {!value.aka &&
                                            <div>{value.name}</div>
                                        }
                                    </div>
                                    <div className='Text18 badge bg-secondary text-wrap rounded float-end me-2'>
                                            <div>{value.cat}</div>
                                    </div>
                                </div>
                                }
                                
                                <div align="center">
                                    <div className="mb-1 rounded"><img src={value.picture} className="img-fluid rounded" alt="">
                                        </img></div>
                                        {isMobile && value.picture_link_text &&
                                            <div className="Text11 mt-1">{value.picture_link_text}</div>
                                        }
                                        {!isMobile && value.picture_link_text &&
                                            <div className="text-center Text13 mt-1">{value.picture_link_text}</div>
                                        }
                                    {value.selected_pic &&
                                        <div className="rounded"><img src={value.selected_pic} className="img-fluid rounded" alt="">
                                        </img></div>
                                    }
                                </div>

                                {value.era &&
                                    <div className="Text13 bg-light rounded mt-2 py-2 ps-3">

                                        <div><b>Era: </b>{value.era}</div>

                                        
                                        {value.region &&
                                        <div>
                                            <b>Region: </b>{value.region}
                                        </div>
                                        }
                                        
                                        {value.movement &&
                                        <div>
                                            <b>Movement: </b>{value.movement}
                                        </div>
                                        }
                                        
                                    </div>
                                }

                                {value.school &&
                                <div className="my-3 ms-2 p-2 Text13 bg-info rounded">
                                        <div><b>School: </b>{value.school}</div>
                                </div>
                                }

                        </div>

                        {/* kolonne 2 */}
                        <div className="col-sm-5">
                            {!isMobile &&
                            <div>
                                <button type="button" className="btn btn-secondary btn-xs col-md-3 float-md-end mb-1 ms-md-2 p-1">
                                <strong>{value.cat}</strong></button>
                            
                                {value.aka &&
                                    <h4><div className="badge bg-primary text-wrap">{value.aka}</div></h4>
                                }
                                {!value.aka &&
                                    <h4><div className="badge bg-primary text-wrap">{value.name}</div></h4>
                                }
                            </div>
                            }

                            
                                
                            <div className="Description p-2">
                                
                                <ReadMoreAndLess
                                        charLimit={DescCharLimit}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess>
                                <div className="Description my-1"> 
                                    <em> -> <a href={value.wiki}>wikipedia..</a></em>
                                </div>
                                
                            </div>
                        
                            {value.main_comment &&
                                <div className="card bg-light px-2 pt-2 mb-2">
                                    <p className="quote13">{value.main_comment}</p> 
                                    <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                </div>
                            }
                            
                            {value.comment &&
                                <div className="row pt-3">
                                        <div className="col-12">
                                            {!value.source_details &&
                                                <div className="card bg-light px-2 pt-2 mb-2">
                                                    <p className="quote13">{value.comment}</p> 
                                                    <div className="blockquote-footer"><i>{value.source}</i></div>
                                                </div>
                                            }
                                            {value.source_details &&
                                                <div className="card bg-light px-2 pt-2 mb-2">
                                                    <p className="quote13">{value.comment}</p> 
                                                    <div className="blockquote-footer"><i>{value.source} ({value.source_details})</i></div>
                                                </div>
                                            }
                                    </div>
                                </div>
                            }
                            

                        </div>
                        
                        {/* kolonne 3 */}
                        <div className="col-sm-4">

                                <div className="row">

                                    <div className="col-12">
                                        <b>Born </b><br />

                                        {age && value.born_place && props.dateSelected === value.born_slug &&
                                            <div className="Date mb-2 p-1 rounded"><b>{born}</b> - {value.born_place} (age {age})</div>
                                        }
                                        {!age && value.born_place && props.dateSelected === value.born_slug &&
                                            <div className="Date mb-2 p-1 rounded"><b>{born}</b> - {value.born_place}</div>
                                        }

                                        {age && value.born_place && !(props.dateSelected === value.born_slug) &&
                                            <div className="Date mb-2 p-1 rounded">{born} - {value.born_place} (age {age})</div>
                                        }
                                        {!age && value.born_place && !(props.dateSelected === value.born_slug) &&
                                            <div className="Date mb-2 p-1 rounded">{born} - {value.born_place}</div>
                                        }

                                        {age && !value.born_place && props.dateSelected === value.born_slug &&
                                            <div className="Date mb-2 p-1 rounded"><b>{born}</b> (age {age})</div>
                                        }
                                        {!age && !value.born_place && props.dateSelected === value.born_slug &&
                                            <div className="Date mb-2 p-1 rounded"><b>{born}</b></div>
                                        }

                                        {age && !value.born_place && !(props.dateSelected === value.born_slug) &&
                                            <div className="Date mb-2 p-1 rounded">{born} (age {age})</div>
                                        }
                                         {!age && !value.born_place && !(props.dateSelected === value.born_slug) &&
                                            <div className="Date mb-2 p-1 rounded">{born}</div>
                                        }
                                        

                                        {value.dead_place && value.dead && (props.dateSelected === value.dead_slug) &&
                                            <div>
                                                <b>Dead </b><br />
                                                <div className="Date mb-2 p-1 rounded"><b>{value.dead}</b> - {value.dead_place}
                                                </div> 
                                            </div>
                                        }

                                        {value.dead_place && value.dead && !(props.dateSelected === value.dead_slug) &&
                                            <div>
                                                <b>Dead </b><br />
                                                <div className="Date mb-2 p-1 rounded">{value.dead} - {value.dead_place}
                                                </div> 
                                            </div>
                                        }

                                        {!value.dead_place && value.dead && (props.dateSelected === value.dead_slug) &&
                                            <div>
                                                <b>Dead </b><br />
                                                <div className="Date mb-2 p-1 rounded"><b>{value.dead}</b>
                                                </div> 
                                            </div>
                                        }

                                        {!value.dead_place && value.dead && !(props.dateSelected === value.dead_slug) &&
                                            <div>
                                                <b>Dead </b><br />
                                                <div className="Date mb-2 p-1 rounded">{value.dead}
                                                </div> 
                                            </div>
                                        }

                                        {value.resting_place &&
                                            <div>
                                                <b>Resting place </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.resting_place}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.nationality &&
                                            <div>
                                                <b>Nationality </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.nationality}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.occupation &&
                                            <div>
                                                <b>Occupation </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.occupation}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.education &&
                                            <div>
                                                <b>Education / Alma mater </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.education}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.known_for &&
                                            <div>
                                                <b>Known for  </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.known_for}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.sub_genres && (value.cat !== 'Music') &&
                                            <div>
                                                <b>Fields </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.sub_genres}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.movement && !value.era &&
                                            <div>
                                                <b>Movement </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.movement}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.notable_work &&
                                            <div>
                                                <b>Notable work </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.notable_work}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.fields &&
                                            <div>
                                                <b>Fields  </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.fields}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.main_interests &&
                                            <div>
                                                <b>Main interests  </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.main_interests}</div>
                                                </div>
                                            </div>
                                        }

                                        {value.notable_ideas &&
                                            <div>
                                                <b>Notable ideas  </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.notable_ideas}</div>
                                                </div>
                                            </div>
                                        }       

                                        {value.awards &&
                                                <div>
                                                    <b>Notable awards</b> <br />
                                                    <div className="factTextNoWrap p-1 rounded"> 
                                                        <ReactMarkdown>
                                                            {value.awards}                    
                                                        </ReactMarkdown> 
                                                    </div>
                                                </div>
                                        }

                                        {value.sub_genres && value.main_genre &&
                                            <div>
                                                <b>Genres </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.main_genre}, {value.sub_genres}</div>
                                                </div>
                                            </div>
                                        }
                                        {!value.sub_genres && value.main_genre &&
                                            <div>
                                                <b>Genres </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.main_genre}</div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            (() => {
                                                if (value.instrument) {
                                                return   <div>
                                                        <b>Instruments </b><br />
                                                        <div>
                                                            <div className="factText mb-2 p-1 rounded">{value.instrument}</div>
                                                        </div>
                                                    </div>
                                                }
                                                return
                                            })()
                                        }

                                        {   
                                            (() => {
                                                if (value.associated_acts) {
                                                return   <div>
                                                        <b>Associated acts </b><br />
                                                        <div>
                                                            <div className="factText mb-2 p-1 rounded">{value.associated_acts}</div>
                                                        </div>
                                                    </div>
                                                }
                                                return
                                            })()
                                        }

                                        {   
                                            (() => {
                                                if (value.years_active) {
                                                return   <div>
                                                        <b>Years active </b><br />
                                                        <div>
                                                            <div className="factText p-1 rounded">{value.years_active}</div>
                                                        </div>  
                                                    </div>
                                                }
                                                return
                                            })()
                                        }


                                        {value.quote1 &&
                                            <div>
                                                <div className="PeopleHeader2 py-1 ps-2 mt-3 mb-2 rounded"><b>Selected quote</b></div>
                                                <div className="card bg-light mb-3 px-2 py-1">
                                                    <div className="quote13">{value.quote1}</div> 
                                                    {value.source1 &&
                                                    <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {value.quote2 &&
                                            <div>
                                                <div className="card bg-light mb-3 px-2 py-1">
                                                    <div className="quote13">{value.quote2}</div> 
                                                    {value.source2 &&
                                                    <div className="blockquote-footer mt-1 quote12"><i>{value.source2}</i></div>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {value.youtube1 && value.youtube2 &&
                                        <div className="row mt-3">
                                        
                                            <div className="col">
                                                <div className="border bg-light mt-1 rounded">
                                                    <ReactPlayer
                                                    url={ value.youtube1 }
                                                    light='true'
                                                    volume={0.9}
                                                    controls={true}
                                                    width="426"
                                                    height="240"
                                                    />
                                                </div>
                                                <div className='factText2 mt-1 rounded'>
                                                    <strong>{value.song1_txt}</strong>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="border bg-light mt-1 rounded">
                                                    <ReactPlayer
                                                    url={ value.youtube2 }
                                                    light='true'
                                                    volume={0.9}
                                                    controls={true}
                                                    width="426"
                                                    height="240"
                                                    />
                                                </div>
                                                <div className='factText2 mt-1 rounded'>
                                                    <strong>{value.song2_txt}</strong>
                                                </div>
                                            </div>

                                        </div>

                                        }  

                                    </div>

                                </div>  
                            
                            </div>
                            
                    </div>
            </div>
        )
    })

  return (
    <div>
      {peopleUnique.length < 1 && 
        <div>
            <h2>Sorry, no notable births or deaths recorded for this date in the database.</h2>
        </div>
      }
      {peopleUnique.length > 0 && 
        <div>
            <div className="row TodayTitle24nobg mx-5">
                <div className="col-sm-12"> 
                    <div className="text-center"><h2>People</h2>
                    </div>
                    {window.location.hostname === "localhost" &&
                    <div>
                        <div className="row my-2">  
                            <div className="col-8">
                                <Select
                                    className="select"
                                    value={optionCat}
                                    onChange={SelectedOptionCat}
                                    options={CatOptions}
                                    placeholder='Select a category..'
                                    styles={customStylesSmall}
                                />
                            </div>
                            <div className="col-4 pl-2">
                                <button  className="btn btn-primary btn-sm"  onClick={() => SetOptionCat(null)} >Reset</button>
                            </div>
                        </div>

                        {!optionCat &&
                            <div>
                                <div className="row">   
                                        <div className="col">
                                        <h4>Search</h4>
                                    </div>
                                </div>
                                                    
                                <div className="row mb-1">  
                                    <div className="col-sm-6 mt-1">
                                        <div className="searchMedium">    
                                            <DebounceInput
                                                //type="number"
                                                minLength={3}
                                                debounceTimeout={500}
                                                value={searchString}
                                                onChange={handleSearchChange}
                                                placeholder="Search all names" 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-1">
                                        <button type="button" className="btn btn-primary"   
                                            onClick={() => clearSearchField()} > Reset search</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    }
                </div>
            </div>

            {!isMobile &&
                <div className="row">
                    <div className="col pb-1 me-2" align="Right"> 
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                        <b>Scroll down</b>
                        </button>
                    </div>
                </div>
            }
         
            {WisePeople}

            
       
        </div>
      }
    </div>
  )
}

export default AnyDayPeople
