import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlPeopleUnranked, dbUrlWTDlist, dbURListsRefswtd } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import dayjs from "dayjs";
import { SelectProperty, SearchLists } from "../misc/utils";
import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';

const PeopleUnrankedLists = () => {
    const [People, setPeople] = useState([])
    const [list, setList] = useState([])
    const [optionNationality, setOptionNationality] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [references, setReferences] = useState([])
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorReferences, setErrorReferences] = useState(null)

    const params = useParams();

    //console.log(params);
    
    useEffect(() => {
        const fetchPeople = () => {
          axios.get(dbUrlPeopleUnranked + params.slug)
          .then(response => {
            setLoading2(false)
            setPeople(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefswtd + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchPeople()
        fetchReferences()
      }, [params.slug])
  
    if (error) return `Error people: ${error.message}`;
    if (errorList) return `Error List: ${errorList.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };


    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>
                                    <p>Here are some helpful links:</p>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/lists'>All Lists</Link></li>
                                    </ul>
                                    
                                
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    let filterArray, nationalityOptions;

    nationalityOptions = SelectProperty(People, 'nationality')

    const selectedNationality = (selectedNationality) => {
        setOptionNationality(selectedNationality);
    }

    //Search
    if (searchString) {
        filterArray = SearchLists(People, 'full_name', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    //Filter
    if (optionNationality) {
        filterArray = People.filter(e => e.nationality === optionNationality.value);
    } 

    if (!searchString && !optionNationality) {
        filterArray = People;
    }



    const {name, description, pic_link, page} = list[0];

    let sortedReferences;

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });



    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>
                
                <h1>{value.name}</h1>

                <div className="row mb-2 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-3 mt-1 Description">

                        <div><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        <div className="text-center">{value.pic_name}</div>


                        {value.pic2_link &&
                        <div className="mt-4 text-center">
                            <img src={value.pic2_link} alt="alt" className="img-fluid" /> 
                        </div>
                        }
                        {value.pic2_name &&
                            <div className="text-center">{value.pic2_name}</div>
                        }

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs mb-2">ALL count: {People.length}</div>
                                </div> 
                                 <div className="text-center">
                                    <div className="btn btn-info btn-xs">FILTER count: {filterArray.length}</div>
                                </div> 
                            </div>
                        }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-5 mt-1">

                        {value.description &&

                            <div className="factText16 rounded p-2">
                                <ReactMarkdown>
                                    {value.description}              
                                </ReactMarkdown>
                            </div>

                        }

                        {value.source_url &&
                            <div className="mt-3">
                                <b>List source</b>
                                <div className="Description">
                                    <ul>
                                        <li>
                                            <b><a href={value.source_url}>
                                                {value.name} ({value.source})
                                            </a></b>
                                        </li>  
                                    </ul>
                                </div>
                            </div>
                        }
                        
                        {value.comment1 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment1}
                                </blockquote>
                                {value.details1 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source1} <cite title="Source Title">({value.details1})</cite>
                                </figcaption>
                                }
                                {!value.details1 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source1}
                                </figcaption>
                                }
                            </figure>
                        }
                        {value.comment2 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment2}
                                </blockquote>
                                {value.details2 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source2} <cite title="Source Title">({value.details2})</cite>
                                </figcaption>
                                }
                                {!value.details2 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source2}
                                </figcaption>
                                }
                            </figure>
                        }
                        {value.comment3 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment3}
                                </blockquote>
                                {value.details3 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source3} <cite title="Source Title">({value.details3})</cite>
                                </figcaption>
                                }
                                {!value.details3 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source3}
                                </figcaption>
                                }
                            </figure>
                        }

                        
                        
                       
                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-4 mt-1">
                        <div className="row">   
                            <div className="col">
                                <h4>Filters</h4>
                            </div>
                        </div>
                        
                       {/*  {!value.no_category &&
                        <div className="row pb-2">  
                            <div className="col-9">
                                <Select
                                    className="select"
                                    value={optionCategory}
                                    onChange={selectedCategory}
                                    options={categoryOptions}
                                    placeholder='Select category'
                                    //styles={customStyles2}
                                />
                            </div>
                            <div className="col-3 pl-2">
                                <button  className="btn btn-primary btn-sm"  onClick={() => setOptionCategory(null)}>Reset</button>
                            </div>
                        </div>
                        } */}

                        <div className="row pb-2">  
                            <div className="col-8">
                                <Select
                                    className="select"
                                    value={optionNationality}
                                    onChange={selectedNationality}
                                    options={nationalityOptions}
                                    placeholder='Select nationality'
                                />
                            </div>
                            <div className="col-4 pl-2">
                                <button  className="btn btn-primary btn-sm"  onClick={() => setOptionNationality(null)} >Reset</button>
                            </div>
                        </div>
                                        
                        <div className="row">   
                                <div className="col">
                                <h5>Search</h5>
                            </div>
                        </div>

                         <div className="row">  
                                <div className="col-8">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all names"
                                            className="searchPeople"
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="button" className="btn btn-primary btn-sm"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>
                        </div> 

                        {searchString &&
                            <div>
                                <div className='mt-3'>
                                    <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                </div> 
                            </div>
                        }
                                
                        

                    </div>
                        
                </div> 
            
                

                <div className="row">
                    <div className="col pb-2" align="Right"> 
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                        <b>Scroll down</b>
                        </button>
                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const PeopleItems = filterArray.map(value => {

        let DescCharLimit

        if (value.charlimit_description) {
            DescCharLimit = value.charlimit_description;
        }
        else {
            DescCharLimit = 1250
        }

        return (

            <React.Fragment key={value.id}>
                {/* Begynne enkelt med simple visning på antall karakterer i description */}
                {value.description.length < 500 &&
                <div className="row py-3 border-bottom border-top border-info">

                    {/* kolonne 1 */}
                    <div className="col-sm-2 ms-5">
                            <div className="mt-2 text-center">
                                <img src={value.picture_link} alt="" className="img-fluid rounded" /> 
                            </div>
                            <div className="mt-3 ms-2 p-2 Text13 bg-light rounded">
                                {value.birth_date &&
                                <div><b>Born: </b>{dayjs(value.birth_date).format('MMMM D, YYYY')}</div>
                                }
                                {value.birth_txt &&
                                <div><b>Born: </b>c. {value.birth_txt}</div>
                                }
                                <div>{value.born_place}</div>

                                {value.dead_date &&
                                <div className="mt-2"><b>Dead: </b>{dayjs(value.dead_date).format('MMMM D, YYYY')}</div>
                                }
                                {value.dead_txt &&
                                <div className="mt-2"><b>Dead: </b>c. {value.dead_txt}</div>
                                }
                                <div>{value.dead_place}</div>

                                {(value.resting_place||value.literature_resting_place) &&
                                    <div className="mt-3"
                                        ><b>Resting place: </b>{value.resting_place||value.literature_resting_place}
                                    </div>
                                }
                                
                                
                            </div>
                            
                        </div>

                    {/* kolonne 2 */}
                    <div className="col-sm-5 mt-2">
                        <button type="button" className="btn btn-secondary btn-xs col-md-3 float-md-end mb-1 ms-md-2 p-1"><strong>{value.occupation}</strong></button>
                            <h4><div className="badge bg-primary text-wrap">{value.first_name} {value.last_name}</div></h4>
                            
                            <div className="Description2 mb-3">
                                <ReadMoreAndLess
                                    charLimit={DescCharLimit}
                                    readMoreText={" Read more ▼"}
                                    readLessText={" Read less ▲"}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess> 
                                <div className="mt-1"><em> -> <a href={value.wikipedia_link}>wikipedia</a></em></div>
                            </div>

                            {value.quote1 && value.year_nobel_prize &&
                            <div className="mt-4 ms-2 p-2 Text18">
                                <div className="PeopleHeader2 py-1 ps-2 mt-1 mb-2 rounded"><b>Selected quote(s)</b></div>
                                {value.quote1 &&
                                    <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote1}</div> 
                                        {value.source1 &&
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                        }
                                    </div>
                                }
                                {value.quote2 &&
                                    <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote2}</div> 
                                        {value.source2 &&
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source2}</i></div>
                                        }
                                    </div>
                                }
                                {value.quote3 &&
                                    <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote3}</div> 
                                        {value.source3 &&
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source3}</i></div>
                                        }
                                    </div>
                                }
                            </div>
                        }

                            {(value.main_interests||value.notable_ideas) &&
                            <div className="row">
                                <div className="col-sm-6 mt-2">
                                    <div>
                                        <div className="PeopleHeader py-1 ps-2"><b>Main interests</b></div>
                                        <div className="pt-2 border-top Text13">
                                            <ReactMarkdown>
                                                {value.main_interests}               
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 mt-2">
                                    <div>
                                        <div className="PeopleHeader py-1 ps-2"><b>Notable ideas</b></div>
                                        <div className="pt-2 border-top Text13">
                                            <ReactMarkdown>
                                                {value.notable_ideas}               
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {(value.notable_work) &&
                                <div>
                                    <div className="PeopleHeader py-1 ps-2"><b>Notable works</b></div>
                                    <div className="pt-2 border-top Text13">
                                        <ReactMarkdown>
                                            {value.notable_work}               
                                        </ReactMarkdown>
                                </div>
                            </div>
                            }
                            
                        </div>

                    {/* kolonne 3 */}
                    <div className="col-sm-4">
                        {value.year_nobel_prize &&
                            <div className="mt-1 ms-2 py-1 px-2 Text18 bg-light rounded">
                                <h4>Nobel Prize</h4>
                                <div><b>Year: </b>{value.year_nobel_prize}</div>
                                {value.age_nobel_prize &&
                                    <div className="mt-2"><b>Age: </b>{value.age_nobel_prize}</div>
                                }
                                {value.nationality &&
                                    <div className="mt-2"><b>Nationality: </b>{value.nationality}</div>
                                }
                                {value.nobel_price_category &&
                                    <div className="mt-2"><b>Genre(s): </b>{value.nobel_price_category}</div>
                                }
                                {value.occupation &&
                                    <div className="mt-2"><b>Occupation: </b>{value.occupation}</div>
                                }
                                {value.people_notable_work &&
                                    <div className="mt-2"><b>Notable work: </b>{value.people_notable_work}</div>
                                }
                                {value.main_comment &&
                                    <div className="card bg-light my-3 px-2 py-1">
                                        <div className="quote13">{value.main_comment}</div> 
                                        {value.main_comment_source &&
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.main_comment_source}</i></div>
                                        }
                                    </div>
                                }
                            </div>
                        }

                    </div>

                </div>

                }
                {value.description.length >= 500 &&
                    <div className="row pt-3 pb-3 border-bottom border-top border-info">

                        {/* kolonne 1 */}
                        <div className="col-sm-3">
                            <div className="mt-2 text-center">
                                <img src={value.picture_link} alt="" className="img-fluid rounded" /> 
                            </div>
                            <div className="mt-3 ms-2 p-2 Text13 bg-light rounded">
                                {value.birth_date &&
                                <div><b>Born: </b>{dayjs(value.birth_date).format('MMMM D, YYYY')}</div>
                                }
                                {value.birth_txt &&
                                <div><b>Born: </b>c. {value.birth_txt}</div>
                                }
                                <div>{value.born_place}</div>

                                {value.dead_date &&
                                <div className="mt-2"><b>Dead: </b>{dayjs(value.dead_date).format('MMMM D, YYYY')}</div>
                                }
                                {value.dead_txt &&
                                <div className="mt-2"><b>Dead: </b>c. {value.dead_txt}</div>
                                }
                                <div>{value.dead_place}</div>

                                {(value.resting_place||value.literature_resting_place) &&
                                    <div className="mt-3"
                                        ><b>Resting place: </b>{value.resting_place||value.literature_resting_place}
                                    </div>
                                }
                                
                                
                            </div>
                            
                        </div>

                        {/* kolonne 2 */}
                        <div className="col-sm-5 mt-2">
                        <button type="button" className="btn btn-secondary btn-xs col-md-3 float-md-end mb-1 ms-md-2 p-1"><strong>{value.occupation}</strong></button>
                            <h4><div className="badge bg-primary text-wrap">{value.first_name} {value.last_name}</div></h4>
                            {value.main_comment && !value.year_nobel_prize &&
                                <div className="card bg-light mb-3 px-2 py-1">
                                    <div className="quote13">{value.main_comment}</div> 
                                    {value.main_comment_source &&
                                    <div className="blockquote-footer mt-1 quote12"><i>{value.main_comment_source}</i></div>
                                    }
                                </div>
                            }

                            <div className="Description2 mb-3">
                                <ReadMoreAndLess
                                    charLimit={DescCharLimit}
                                    readMoreText={" Read more ▼"}
                                    readLessText={" Read less ▲"}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess> 
                                <div className="mt-1"><em> -> <a href={value.wikipedia_link}>wikipedia</a></em></div>
                            </div>
                            {(value.main_interests||value.notable_ideas) &&
                            <div className="row">
                                <div className="col-sm-6 mt-2">
                                    <div>
                                        <div className="PeopleHeader py-1 ps-2"><b>Main interests</b></div>
                                        <div className="pt-2 border-top Text13">
                                            <ReactMarkdown>
                                                {value.main_interests}               
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 mt-2">
                                    <div>
                                        <div className="PeopleHeader py-1 ps-2"><b>Notable ideas</b></div>
                                        <div className="pt-2 border-top Text13">
                                            <ReactMarkdown>
                                                {value.notable_ideas}               
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {value.notable_work &&
                                <div>
                                    <div className="PeopleHeader py-1 ps-2"><b>Notable works</b></div>
                                    <div className="pt-2 border-top Text13">
                                        <ReactMarkdown>
                                            {value.notable_work}               
                                        </ReactMarkdown>
                                </div>
                            </div>
                            }
                            
                        </div>

                        {/* kolonne 3 */}
                        <div className="col-sm-4">
                            
                            {value.quote1 && !value.year_nobel_prize &&
                            <div>
                                <div className="PeopleHeader2 py-1 ps-2 mb-2 rounded"><b>Selected quotes</b></div>
                                {value.quote1 &&
                                    <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote1}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                    </div>
                                }
                                {value.quote2 &&
                                    <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote2}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                    </div>
                                }
                                {value.quote3 &&
                                    <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote3}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                    </div>
                                }
                            </div>
                            }
                            {value.school &&
                                <div className="my-3 ms-2 p-2 Text18 bg-info rounded">
                                    <div className="mt-1 ms-2 p-2 rounded">
                                        <div><b>School: </b>{value.school}</div>
                                    </div>
                                </div>
                            }
                            {(value.era || value.english_era) &&
                                <div className="mt-3 ms-2 p-2 Text18 bg-light rounded">

                                    <div><b>Era: </b>{value.era}</div>

                                    {value.region &&
                                        <div className="mt-2"><b>Region: </b>{value.region}</div>
                                    }
                                    
                                    {value.movement &&
                                        <div className="mt-2"><b>Movement: </b>{value.movement}</div>
                                    }
                                    
                                </div>
                            }
                            {value.year_nobel_prize &&
                                <div className="mt-1 ms-2 py-1 px-2 Text18 bg-light rounded">
                                    <h4>Nobel Prize</h4>
                                    <div><b>Year: </b>{value.year_nobel_prize}</div>
                                    {value.age_nobel_prize &&
                                        <div className="mt-2"><b>Age: </b>{value.age_nobel_prize}</div>
                                    }
                                    {value.nationality &&
                                        <div className="mt-2"><b>Nationality: </b>{value.nationality}</div>
                                    }
                                    {value.nobel_price_category &&
                                        <div className="mt-2"><b>Genre(s): </b>{value.nobel_price_category}</div>
                                    }
                                    {value.occupation &&
                                        <div className="mt-2"><b>Occupation: </b>{value.occupation}</div>
                                    }
                                    {value.people_notable_work &&
                                        <div className="mt-2"><b>Notable work: </b>{value.people_notable_work}</div>
                                    }
                                    {value.main_comment &&
                                        <div className="card bg-light my-3 px-2 py-1">
                                            <div className="quote13">{value.main_comment}</div> 
                                            {value.main_comment_source &&
                                            <div className="blockquote-footer mt-1 quote12"><i>{value.main_comment_source}</i></div>
                                            }
                                        </div>
                                    }
                                </div>
                                }

                                {value.quote1 && value.year_nobel_prize &&
                                    <div className="mt-4 ms-2 p-2 Text18">
                                        <div className="PeopleHeader2 py-1 ps-2 mt-1 mb-2 rounded"><b>Selected quote(s)</b></div>
                                        {value.quote1 &&
                                            <div className="card bg-light mb-3 px-2 py-1">
                                                <div className="quote13">{value.quote1}</div> 
                                                {value.source1 &&
                                                <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                                }
                                            </div>
                                        }
                                        {value.quote2 &&
                                            <div className="card bg-light mb-3 px-2 py-1">
                                                <div className="quote13">{value.quote2}</div> 
                                                {value.source2 &&
                                                <div className="blockquote-footer mt-1 quote12"><i>{value.source2}</i></div>
                                                }
                                            </div>
                                        }
                                        {value.quote3 &&
                                            <div className="card bg-light mb-3 px-2 py-1">
                                                <div className="quote13">{value.quote3}</div> 
                                                {value.source3 &&
                                                <div className="blockquote-footer mt-1 quote12"><i>{value.source3}</i></div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }

                        </div>

                    </div>
                }
                

                

            </React.Fragment>

        )

    })

    //REFERENCES
    const referenceItems = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>
                {
                        (() => {
                        if (value.book_title) {
                        return  <div className="row px-1 my-1">
                                    <div className="col">
                                        <div>{value.author_txt} ({value.published_year}). 
                                        &nbsp;<a href={value.more_info}>{value.book_title}</a>. {value.publisher}. (book) </div>
                                    </div>
                                </div>
                        } if (!value.date_txt && value.person) {
                        return <div className="row px-1 my-1">
                                <div className="col">
                                    <a href={value.url}>
                                        {value.title}
                                    </a> ({value.person})
                                </div>
                            </div>
                        } if (!value.date_txt && !value.person) {
                            return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        }
                        if (!value.person) {
                            return <div className="row px-1 my-1">
                                        <div className="col">
                                            <a href={value.url}>
                                                {value.title}
                                            </a> ({value.date_txt})
                                        </div>
                                    </div>
                        }
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        {value.person} ({value.date_txt}). <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        })()
                    }
            </React.Fragment>
        )
    })
    
    return (
        <main className="my-1 py-1">
            {People.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {PeopleItems}
                        </div>
                    </div>

                    {(references.length > 0) &&
                        <div>
                                <div className="row mt-4">
                                    <div className="col">
                                            <h4>References</h4>
                                    </div>
                                </div>
                                <div className="row px-1">
                                    <div className="col">
                                        <b>
                                        <a href='https://en.wikipedia.org'>
                                            Wikipedia
                                        </a>
                                        </b>
                                    </div>
                                </div>
                            
                                <div className="row my-2">
                                    <div className="col">
                                            { referenceItems }
                                    </div>
                                </div>
                        </div>   
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default PeopleUnrankedLists